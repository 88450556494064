import 'normalize.css'
import './fonts/stylesheet.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKWOtziAl69bca1M9AERhF4ezqSXemF7Y",
  authDomain: "wink-natal.firebaseapp.com",
  databaseURL: "https://wink-natal-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wink-natal",
  storageBucket: "wink-natal.appspot.com",
  messagingSenderId: "207326436457",
  appId: "1:207326436457:web:f4b73338ee6d7fcadb96f8"
};
// Initialize Firebase
initializeApp(firebaseConfig);
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
const db = getDatabase();
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(db, "localhost", 9000);
} 
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
