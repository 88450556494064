<template>
<div class="modal">
  <div class="modal_content" v-if="message">
    <p v-html="message"></p>
    <div class="buttons">
      <button type="submit" @click.prevent="$emit('close')">Voltar</button>
    </div>
  </div>
  <div class="modal_content" v-else>
    <h4>Confirme os dados submetidos</h4>
    <h3>Código</h3>
    <span v-text="modalData.code"></span>
    <h3>Desejo: </h3><span v-text="modalData.wish"></span>
    <h3>Nome: </h3><span v-text="modalData.name"></span>
    <h3>Email: </h3><span v-text="modalData.email"></span>
    <div class="buttons">
      <button type="submit" @click.prevent="$emit('close')">Voltar</button>
      <button type="submit" @click.prevent="submit">Confirmar</button>
    </div>
  </div>
</div>
</template>

<script>
// import { getDatabase, ref, remove, set } from "firebase/database";

export default {
  name: 'Modal',
  props: {
    modalData: {required: true}
  },
  data() {
    return {
      message: '',
      error: false,
    }
  },
  methods: {
    submit() {
      this.modalData.submit()
      .then( () => {
        this.message = "O seu desejo foi enviado com sucesso"
        this.modalData.reset()
      })
      .catch( () => {
        this.message = "Erro ao enviar<br>Este código já foi utilizado"
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/_queries.scss';
.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgba(black, .4);
  z-index: 10000;
  display: flex;
  .buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
  }
  &_content {
    padding: 1rem;
    background-color: var(--second-color);
    max-height: 90vh;
    max-width: 400px;
    overflow: auto;
    margin: auto;
    width: 80%;
    height: fit-content;
    margin: auto;
    border-radius: 10px;
    span {
      color: white;
    }
    button {
      flex-grow: 1;
      min-width: 0;
      flex-basis: 0;
      border: 2px solid var(--main-color);
      width: fit-content;
      background-color: var(--main-color);
      color: white;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 1em 0;
      font-size: .65em;
      @include media(tablet) {
        font-size: .85em;
      }
      transition: .3s ease-out background-color;
      margin: .5em auto 0;
      &:hover {
        background-color: var(--second-color);
      }
    }
  }
}
</style>
