<template>
  <div id="app">
    <div class="background">
      <h1 class="title"><strong>os teus</strong><br><i>desejos</i> <strong>de<br><span class="big">natal</span></strong></h1>
    </div>
    <div class="foreground" :style="mouseMove">
      <div class="light snow"></div>
      <div class="light light1"></div>
      <div class="light light2"></div>
      <div class="light light3"></div>
    </div>
    <div class="content">
      <div id="nav">
        <router-link :to="{name: 'Privacy'}">Política de Privacidade</router-link>
        <router-link :to="{name: 'Participacao'}">Condições de Participação</router-link>
      </div>
      <div class="logo">
        <router-link class="logo" to="/">
          <img src="./assets/logo-full-white.webp" alt="" srcset="">
        </router-link>
      </div>
      <router-view class="view" @modal="modal"/>
    </div>
    <Modal v-if="modalData" :modalData="modalData" @close="modalData = null"/>
  </div>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal,
  },
  data() {
    return {
      mousex: 0,
      mousey: 0,
      mouseTO: null,
      modalData: null,
    }
  },
  methods: {
    mouseUpdate(event) {
      clearTimeout(this.mouseTo)
      this.mouseTo = setTimeout(() => {
        this.mousex = `${(event.x / window.innerWidth) * 100}%`
        this.mousey = `${(event.y / window.innerHeight) * 100}%`
      }, 20)
    },
    modal(form) {
      this.modalData = form
    },
  },
  computed: {
    mouseMove() {
      return {
        '--mouse-y': this.mousey,
        '--mouse-x': this.mousex,
      }
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.mouseUpdate, false)
  },
  destroyed() {
    document.removeEventListener('mousemove', this.mouseUpdate, false)
  }
}
</script>

<style lang="scss">
@import './assets/_queries.scss';

.foreground, .background {
  pointer-events: none;
}
.foreground {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  overflow: hidden;
}
.light {
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &1 { // TOP LEFT
    background-position: top left;
    // transform: translateX(calc(var(--mouse-x) / -50 )) translateY(calc(var(--mouse-y) / -40));
    background-image: url('./assets/light1_mobile.png');
    background-size: 100%;
    @include media(tablet) {
      background-size: 50%;
      background-image: url('./assets/light1.png');
    }
    @include media(desktop) {
      background-size: auto;
    }
    @include media(large) {
      background-size: 30%;
    }
  }
  &2 { // BOTTOM LEFT
    background-position: bottom left;
    background-image: url('./assets/light2_mobile.png');
    background-size: 40%;
    @include media(tablet) {
      background-image: url('./assets/light2.png');
      background-size: auto;
    }
    // transform: translateX(calc(var(--mouse-x) / -55 )) translateY(calc((100% - var(--mouse-y)) / 35));
    @include media(large) {
      background-size: 15%;
    }
  }
  &3 { // TOP RIGHT
    background-image: url('./assets/light3_mobile.png');
    background-size: 20%;
    background-position: right 10%;
    @include media(tablet) {
      background-image: url('./assets/light3.png');
      background-size: auto;
    }
    // transform: translateX(calc((100% - var(--mouse-x)) / 60 )) translateY(calc(var(--mouse-y) / -45));
    @include media(large) {
      background-size: 15%;
    }
  }
  &.snow {
    background-image: url('./assets/snow.png');
    background-position: bottom right;
    background-size: 100% auto;
    filter:
      drop-shadow(0px -8px 8px rgba(black, .075))
      drop-shadow(0px -8px 90px rgba(black, .05));
    @include media(desktop) {
      @media screen and (max-height: 700px) {
        display: none;
      }
    }
  }
}
.view {
  position: relative;
  z-index: 12;
  flex-grow: 1;
}

body, html {
  min-height: 100vh;
  background-color: var(--second-color);
  font-size: 16px;
}
body {
  --main-color: rgb(229, 81, 113);
  --second-color: rgb(245, 204, 211);
  --max-width: 1600px;
  --nav-height: 17vh;
  --mobile-padding: 10vw;
}
* {
  box-sizing: border-box;
}
#app {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  text-align: center;
  color: var(--main-color);
  min-height: 100vh;
  z-index: 1;
  background-size: cover;
  background-position: center;
  position: relative;
  > .content {
    z-index: 10;
    position: relative;
    display: grid;
    grid-template-areas:
      'logo'
      'view'
      'menu';
    gap: 3rem;
    padding: 4rem var(--mobile-padding) 8rem;
    @include media(desktop) {
      grid-template-areas:
        'menu logo'
        'view view';
      grid-template-columns: 1fr min-content;
      gap: 3.5vw;
      padding: 0 10vw;
    }
    max-width: calc(var(--max-width) + 10vw);
    margin: auto;
    @include media(desktop) {
      padding: 0 80px;
    }
    .logo {
      grid-area: logo;
      @include media(desktop) {
        height: var(--nav-height);
      }
      display: flex;
      width: 100%;
      img {
        max-width: 80px;
        @include media(desktop) {
          max-width: 150px;
        }
        height: auto;
        margin: auto;
      }
    }
    .view {
      grid-area: view;
      margin: auto;
      width: 100%;
      max-width: 1600px;
    }
  }
  --mouse-y: 0%;
  --mouse-x: 100%;
  .background {
    background-image: url("./assets/snowflakes.png");
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    > .title {
      font-family: 'TTRamillas', Helvetica, Arial, sans-serif;
      position: absolute;
      top: -50vh;
      font-weight: normal;
      // top: 0;
      line-height: 1;
      bottom: 0;
      left: -5vh;
      right: 0;
      font-size: 45vh;
      margin: auto auto 0;
      opacity: .1;
      width: max-content;
      text-align: left;
      .big {
        font-size: 1.75em;
        line-height: .6;
      }
    }
  }
}

#nav {
  grid-area: menu;
  margin: auto;
  max-width: var(--max-width);
  width: 100%;
  height: 100px;
  z-index: 200;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: .7rem;
  gap: 2rem;
  justify-content: space-between;
  @include media(desktop) {
    height: var(--nav-height);
    justify-content: flex-end;
    gap: 3.5vw;
    text-align: right;
    font-size: .85rem;
  }
  @include media(large) {
    padding: 0;
  }

  .logo {
    width: 10%;
    min-width: 0;
    max-width: 200px;
    display: block;
    img {
      width: 100%;
    }
  }

  a {
    font-weight: bold;
    color: var(--main-color);
    text-decoration: none;
    &.router-link-exact-active {
      // color: #42b983;
    }
  }
}
</style>
