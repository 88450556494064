<template>
  <div class="home">
    <div class="title" :style="titleStyle"></div>
    <!-- <WinkForm class="form" @modal="modal"/> -->
    <div class="form">
      <h2>Vencedoras</h2>
      <ol>
        <li>Sara Sebastião</li>
        <li>Rafaela Espírito Santo</li>
        <li>Ana Rita Fróis</li>
        <li>Helena Nascimento</li>
        <li>Eliana Almeida</li>
        <li>Andreia Ferreira</li>
        <li>Mónica Fernandes</li>
        <li>Fabiana Alves</li>
        <li>Renata Coral</li>
        <li>Ana Cláudia Cardoso da Silva</li>
      </ol>
      <p class="note">*Serão contactadas por e-mail, através do contacto submetido anteriormente. Estejam atentas à caixa de entrada e spam!</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import WinkForm from '@/components/Form.vue'

export default {
  name: 'Home',
  components: {
    // WinkForm,
  },
  computed: {
    titleStyle() {
      return {
        // 'background-image': `url(${TITLE})`,
      }
    },
  },
  methods: {
    modal(el) {
      this.$emit('modal', el)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/_queries.scss";

.home {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc((100vw - var(--mobile-padding) * 2) * 387 / 687) 1fr;
  @include media(tablet-down) {
    gap: 3rem;
  }
  @include media(desktop) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding-bottom: 20vh;
  }
}
.title {
  background-image: url("../assets/title_mobile.png");
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 40vh;
  height: 100%;
  width: 100%;
  margin: 0 auto auto;
  @include media(desktop) {
    background-position: center;
    max-height: 42vh;
  }
}
.form {
  margin: auto;
  width: 100%;
  margin: auto auto auto 0;
  h2 {
    text-align: left;
    font-family: 'TTRamillas', Helvetica, Arial, sans-serif;
    font-size: 4rem;
    margin-top: 0;
  }
  ol {
    width: fit-content;
    margin: 0;
    li {
      padding: .25rem 0;
      text-align: left;
    }
  }
  .note {
    text-align: left;
    font-weight: normal;
    font-size: .75rem;
    max-width: 350px;
  }
}
</style>
